<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-sys__dict">
      <el-table
        v-loading="dataListLoading"
        :data="dataList"
        border
        @selection-change="dataListSelectionChangeHandle"
        @sort-change="dataListSortChangeHandle"
        style="width: 100%"
      >
        <el-table-column
          prop="orderSn"
          :label="$t('statement.orderSn')"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          :label="$t('statement.dateCycle')"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span> {{ scope.row.startDate }} - </span>
            <span>{{ scope.row.endDate }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="channelName"
          :label="$t('statement.channelName')"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="settlementPrice"
          :label="$t('statement.price')"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.settlementPrice / 100 }}</span></template
          >
        </el-table-column>
        <el-table-column
          prop="finalPrice"
          :label="$t('statement.finalPrice')"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.finalPrice / 100 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="statementStatus_name"
          :label="$t('statement.status')"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="statementDate"
          :label="$t('statement.date')"
          header-align="center"
          align="center"
          width="180"
        ></el-table-column>
        <el-table-column
          :label="$t('handle')"
          fixed="right"
          header-align="center"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.statementStatus == 1"
              type="text"
              size="small"
              @click="addOrUpdateHandle(scope.row.id)"
              >{{ $t('statement.toStatement') }}</el-button
            >
            <el-button
              v-if="scope.row.statementStatus == 2"
              type="text"
              size="small"
              @click="addOrUpdateHandle(scope.row.id)"
              >{{ $t('statement.view') }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle"
      >
      </el-pagination>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update
        v-if="addOrUpdateVisible"
        ref="addOrUpdate"
        @refreshDataList="getDataList"
      ></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module';
import AddOrUpdate from './statement-add-or-update';
import { addDynamicRoute } from '@/router';
export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/web/api/finalStatement/list',
        getDataListIsPage: true,
      },
      dataForm: {
        id: '0',
        dictName: '',
        dictType: '',
      },
      visible: false,
    };
  },
  components: {
    AddOrUpdate,
  },
  methods: {
    // 子级
    childHandle(row) {
      // 路由参数
      const routeParams = {
        routeName: `${this.$route.name}__${row.id}`,
        title: `${this.$route.meta.title} - ${row.dictType}`,
        path: 'sys/dict-data',
        params: {
          dictTypeId: row.id,
        },
      };
      // 动态路由
      addDynamicRoute(routeParams, this.$router);
    },
  },
};
</script>
