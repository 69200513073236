<template>
  <el-dialog
    :visible.sync="visible"
    :title="$t('statement.detail')"
    :close-on-click-modal="false"
    :before-close="resetData"
    :close-on-press-escape="false"
    width="80%"
  >
    <el-card>
      <el-form
        :model="dataForm"
        ref="dataForm"
        @keyup.enter.native="dataFormSubmitHandle()"
        label-width="150px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item prop="orderSn" :label="$t('statement.orderSn')">
              {{ dataForm.orderSn }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              prop="channelName"
              :label="$t('statement.channelName')"
            >
              {{ dataForm.channelName }}
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item prop="status" :label="$t('statement.status')">
              {{ dataForm.statementStatus_name }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="dateCycle" :label="$t('statement.dateCycle')">
              {{ dataForm.startDate }} - {{ dataForm.endDate }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="settlementPrice" :label="$t('statement.price')">
              {{ dataForm.settlementPrice }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="finalPrice" :label="$t('statement.finalPrice')">
              <el-input
                :disabled="dataForm.statementStatus == 1 ? false : true"
                v-model="dataForm.finalPrice"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card>
      <div slot="header">
        <span>{{ $t('statement.item') }}</span>
      </div>
      <el-table :data="dataForm.detailList" border style="min-width: 100%">
        <el-table-column
          :label="$t('statistics.adPlaceName')"
          prop="adPlaceName"
          align="center"
        >
        </el-table-column>
        <el-table-column
          :label="$t('statistics.clickCount')"
          prop="clickCount"
          align="center"
        >
        </el-table-column>
        <el-table-column
          :label="$t('statement.price')"
          prop="settlementPrice"
          align="center"
        >
        </el-table-column>
      </el-table>
    </el-card>
    <template slot="footer">
      <el-button v-if="dataForm.statementStatus != 1" @click="visibleDismiss">{{
        $t('statement.close')
      }}</el-button>
      <el-button
        type="primary"
        v-if="dataForm.statementStatus == 1"
        @click="dataFormSubmitHandle()"
        >{{ $t('confirm') }}</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce';
import mixinViewModule from '@/mixins/view-module';
import region from '../sys/region.vue';
let _minPackageTime = null;
let _maxPackageTime = null;
let _minRightsTime = null;
let _maxRightsTime = null;
export default {
  components: { region },
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: false,
      },
      dataForm: {
        id: '',
        finalPrice: '',
      },
      options: [
        {
          value: '01',
          label: '时间点',
        },
        {
          value: '02',
          label: '时间段',
        },
      ],
      statusOptions: [
        {
          value: 0,
          label: '禁用',
        },
        {
          value: 1,
          label: '正常',
        },
      ],
      activateDate: [],
      usefulPeriodDate: [],
      packageDate: [],
      dataList: [],
      rightsItem: {},
      channelList: [],
      productList: [],
      smsList: [],
      packageOptions: {
        disabledDate(time) {
          // onPick后触发
          // 该方法会轮询当3个月内的每一个日期，返回false表示该日期禁选
          if (_minPackageTime && _maxPackageTime) {
            return (
              time.getTime() <= _minPackageTime ||
              time.getTime() > _maxPackageTime
            );
          }
        },
      },
      rightsOptions: {
        disabledDate(time) {
          // onPick后触发
          // 该方法会轮询当3个月内的每一个日期，返回false表示该日期禁选
          if (_minRightsTime && _maxRightsTime) {
            return (
              time.getTime() <= _minRightsTime ||
              time.getTime() > _maxRightsTime
            );
          }
        },
      },
      rightsList: [],
      visible: false,
    };
  },
  computed: {
    dataRule() {
      return {
        packageName: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
          { min: 3, message: '长度须大于3位', trigger: 'blur' },
        ],
        packageCode: [
          {
            required: false,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        status: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        startDate: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        endDate: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
      };
    },
  },
  methods: {
    visibleDismiss() {
      this.visible = false;
      this.resetData();
    },
    resetData() {
      Object.assign(this.$data, this.$options.data());
    },
    init() {
      this.visible = true;
      if (this.dataForm.id) {
        this.getInfo();
      }
    },
    // 获取信息
    getInfo() {
      this.$http
        .get(`/web/api/finalStatement/detail/${this.dataForm.id}`)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          res.data.finalPrice = (res.data.finalPrice / 100).toFixed(2);
          res.data.settlementPrice = (res.data.settlementPrice / 100).toFixed(
            2
          );
          res.data.detailList.forEach((element) => {
            element.settlementPrice = (element.settlementPrice / 100).toFixed(
              2
            );
          });
          this.dataForm = {
            ...this.dataForm,
            ...res.data,
          };
          console.log(this.dataForm);
        })
        .catch(() => {});
    },
    // 表单提交
    dataFormSubmitHandle: debounce(
      function () {
        this.$refs['dataForm'].validate((valid) => {
          if (!valid) {
            return false;
          }
          this.dataForm.finalPrice = this.dataForm.finalPrice * 100;
          this.$http
            .post('/web/api/finalStatement/settle', this.dataForm)
            .then(({ data: res }) => {
              if (res.code !== 0) {
                return this.$message.error(res.msg);
              }
              this.$message({
                message: this.$t('prompt.success'),
                type: 'success',
                duration: 500,
                onClose: () => {
                  this.visible = false;
                  this.$emit('refreshDataList');
                },
              });
              this.resetData();
            })
            .catch(() => {});
        });
      },
      1000,
      { leading: true, trailing: false }
    ),
  },
};
</script>
